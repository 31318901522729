// //REAL
module.exports = {
  address: "0x99F0501F97A2ad7dC5D8C99B56ea5FfBFbB60121",
  collection: "https://opensea.io/collection/unhuman-compositions",
};

// module.exports = {
//   address: "0x958Bc1Cea62F986220adB19f69605d4d84347F70",
//   collection: "https://testnets.opensea.io/collection/unhuman-compositions",
// };
